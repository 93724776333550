import React, { useReducer, useContext } from "react"
import { initialState, reducer } from "./AuthReducer"
import AuthContext from "./AuthContext"

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  )
}

/**
 * We export this useContext hook so that we can access
 * state and dispatch events in functional components
 * with our own hook
 */
function useAuth() {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }
