module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bssr-website","short_name":"bssr","start_url":"/","background_color":"#2B2A60","theme_color":"#2B2A60","display":"minimal-ui","icon":"src/images/bssr-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"19881fbeb770bacdce497cd233bdc11d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","plugins":[null]}],"fields":[{"name":"given_name","store":true},{"name":"family_name","store":true},{"name":"role","store":true},{"name":"email","store":true},{"name":"user_id","store":true},{"name":"account_type","store":true},{"name":"picture","store":true},{"name":"user_metadata","store":true}],"resolvers":{"Auth0User":{}},"filename":"search_index.json"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
