import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { AuthProvider } from "./src/providers/auth/AuthProvider"

import "./src/styles/app.css"

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      redirectUri={process.env.GATSBY_AUTH0_REDIRECT}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <AuthProvider>{element}</AuthProvider>
    </Auth0Provider>
  )
}
