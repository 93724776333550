/**
 * Initial state must be set, this is then passed into the
 * reducer as default state.
 */
const initialState = {}

/**
 * We use a reducer to store user data globally (within the context of a provider).
 * We interact with the reducer through actions that we call via `dispatch` e.g.
 * `dispatch({ type: 'chomp', payload: name });`
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "setUser":
      return {
        ...state,
        ...action.payload,
      }

    default:
      throw new Error(`Unhandled action type ${action.type}`)
  }
}

export { initialState, reducer }
